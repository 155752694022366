.charity-info-section {
    background-color: #FFFFFF;
    padding: 60px 0;
  }
  
  .charity-info-section .charity-info-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .charity-info-section .charity-card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 60px;
  }
  
  .charity-info-section .charity-card {
    width: 100%;
    max-width: 373px;
    height: auto;
    min-height: 203px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  .charity-info-section .charity-card-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .charity-info-section .charity-card-content {
    flex: 1;
    text-align: left;
  }
  
  .charity-info-section .charity-card-content h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px;
    color: #333;
  }
  
  .charity-info-section .charity-card-content p {
    font-size: 12px;
    color: #666;
    margin: 0;
  }
  
  .charity-info-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
  
  .charity-info-image,
  .charity-info-text {
    width: 100%;
    max-width: 100%;
  }
  
  .charity-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .charity-info-text h4 {
    color: #EC9F16;
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .charity-info-text h2 {
    font-size: clamp(24px, 5vw, 36px);
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  .charity-info-text > p {
    font-size: clamp(14px, 3vw, 16px);
    margin-bottom: 24px;
  }
  
  .charity-info-goals {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 24px;
  }
  
  .charity-goal {
    display: flex;
    align-items: center;
  }
  
  .charity-goal-icon {
    width: 40px;
    height: 40px;
    border: 2px solid #EC9718;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
  }
  
  .charity-goal-text h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .charity-goal-text p {
    font-size: 14px;
    color: #666;
  }
  
  .charity-discover-button {
    background-color: #EC9718;
    color: #FFFFFF;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .charity-discover-button:hover {
    background-color: #D88A16;
  }
  
  @media (min-width: 576px) {
    .charity-info-goals {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .charity-goal {
      flex-basis: calc(50% - 10px);
    }
  }
  
  @media (min-width: 768px) {
    .charity-info-section .charity-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (min-width: 992px) {
    .charity-info-section .charity-card {
      width: calc(33.33% - 20px);
    }
  
    .charity-info-section .charity-info-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .charity-info-image,
    .charity-info-text {
      flex-basis: 48%;
      max-width: 48%;
    }
  
    .charity-goal {
      flex-basis: auto;
    }
  }