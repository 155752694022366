.gallery-section {
    padding: 48px 0;
    background-color: #f3f4f6;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
  }
  h1 {
    font-size: 48px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 32px;
  }
  .gallery-container {
    display: flex;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .gallery-card {
    flex-shrink: 0;
    width: 256px;
    height: 320px;
    margin: 0 8px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
  }
  .gallery-card:hover {
    transform: scale(1.05);
  }
  .gallery-card img,
  .gallery-card video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: background-color 0.3s ease;
  }
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  @keyframes fadeInScale {
    from {
      opacity: 0.5;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  .gallery-card {
    animation: fadeInScale 0.5s ease forwards;
  }