@import '../../assets/colors/color.css';

.donation-section {
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .donation-container {
    width: 1429px;
    height: 784px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    max-height: 90vh;
  }
  
  .donation-image {
    width: 50%;
    height: 100%;
    overflow: hidden;
  }
  
  .donation-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .donation-content {
    width: 50%;
    padding: 40px;
    background-color: var(--color-background-secondary);
    background-image: linear-gradient(to top right, var(--color-background-secondary), var(--color-background-primary));
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
  }
  
  .donation-content h2 {
    font-size: 36px;
    font-weight: bold;
    color: var(--color-text-primary);
    margin-bottom: 20px;
  }
  
  .donation-content p {
    font-size: 16px;
    color: var(--color-text-secondary);
    margin-bottom: 30px;
  }
  
  .notice {
    background-color: var(--color-background-primary);
    border-left: 4px solid var(--color-background-secondary);
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
  }
  
  .donation-input {
    position: relative;
    margin-bottom: 30px;
  }
  
  .currency-symbol {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: var(--color-text-secondary);
  }
  
  .donation-input input {
    width: 100%;
    padding: 15px 15px 15px 35px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-background-primary);
    box-sizing: border-box;
  }
  
  .donation-input input:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-input-focus);
  }
  
  .amount-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .amount-buttons button {
    padding: 10px;
    background-color: var(--color-accent-secondary);
    border: 1px solid var(--color-background-primary);
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .amount-buttons button:hover {
    background-color: var(--color-background-primary);
  }
  
  .donate-button {
    align-self: center;
    padding: 15px 30px;
    background-color: var(--color-button-primary);
    color: var(--color-button-text);
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .donate-button:hover {
    background-color: var(--color-accent-primary);
    color: var(--color-button-text);
  }
  
  @media (max-width: 1440px) {
    .donation-container {
      width: 100%;
      height: auto;
      aspect-ratio: 1429 / 784;
    }
  }
  
  @media (max-width: 1024px) {
    .donation-container {
      flex-direction: column;
      height: auto;
      aspect-ratio: auto;
    }
  
    .donation-image,
    .donation-content {
      width: 100%;
    }
  
    .donation-image {
      height: 300px;
    }
  }
  
  @media (max-width: 768px) {
    .donation-section {
      padding: 30px 10px;
    }
  
    .donation-content {
      padding: 30px 20px;
    }
  
    .donation-content h2 {
      font-size: 28px;
    }
  
    .donation-content p {
      font-size: 14px;
    }
  
    .amount-buttons {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .amount-buttons button {
      font-size: 14px;
      padding: 8px;
    }
  
    .donate-button {
      padding: 12px 24px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .amount-buttons {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .amount-buttons button {
      font-size: 12px;
      padding: 6px;
    }
  }