.beliefs-section {
    background-color: #9BAFDE;
    border-radius: 20px;
    padding: 60px 40px;
    margin: 40px 0;
    color: white; /* Added: all text content white */
  }
  
  .beliefs-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
  }
  
  .beliefs-text {
    flex: 1;
    padding-right: 40px;
  }
  
  .beliefs-text h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .beliefs-text h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .beliefs-text p {
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .more-about-us {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease; /* Modified: added transition for smooth color change */
    border-radius: 25px; /* Added: rounded border radius */
  }
  
  .more-about-us:hover {
    background-color: black;
    color: white;
  }
  
  .beliefs-image {
    flex: 1;
  }
  
  .beliefs-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .join-us {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .join-us h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .join-us p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .join-us-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .join-us-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .attend-in-person {
    background-color: black;
    color: white;
    border: none;
  }
  
  .attend-online {
    background-color: white;
    color: black;
    border: none;
  }
  
  .attend-in-person:hover,
  .attend-online:hover {
    transform: scale(1.05);
  }
  
  .video-section {
    width: 100%;
    margin-top: 40px;
  }

  .video-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  
  .video-container video {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    border-radius: 10px;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: pulse 1.5s infinite;
  }
  
  .play-button:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.1);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  @media (max-width: 768px) {
    .beliefs-content {
      flex-direction: column;
    }
  
    .beliefs-text,
    .beliefs-image {
      width: 100%;
      padding-right: 0;
      margin-bottom: 40px;
    }
  
    .join-us-buttons {
      flex-direction: column;
    }
  
    .join-us-buttons button {
      width: 100%;
    }

    .play-button {
      width: 60px;
      height: 60px;
    }
  }