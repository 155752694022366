.mission-statement {
  background-color: white;
  padding: 80px 20px;
  text-align: center;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.mission-statement.animate {
  opacity: 1;
  transform: translateX(0);
}

.mission-title {
  font-size: 36px;
  font-weight: bold;
  color: black;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.3;
}

.mission-title .highlight {
  color: #FFA500; /* Orange color */
}

.mission-description {
  font-size: 18px;
  color: #666; /* Gray color */
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mission-statement {
    padding: 60px 20px;
  }

  .mission-title {
    font-size: 28px;
  }

  .mission-description {
    font-size: 16px;
  }
}