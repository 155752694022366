.sermon-section {
    background-color: #183135;
    border-radius: 50px;
    padding: 60px 40px;
    margin-top: -50px;
  }
  
  .sermon-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
  
  .sermon-content {
    flex: 1;
  }
  
  .sermon-content h3 {
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .sermon-content h2 {
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 30px;
  }
  
  .sermon-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .sermon-buttons button {
    background-color: #ECCE68;
    color: #183135;
    border: none;
    border-radius: 25px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
  }
  
  .sermon-buttons button:hover {
    background-color: white;
  }
  
  .sermon-video {
    flex: 1;
    position: relative;
    max-width: 640px;
  }
  
  .sermon-video img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: pulse 2s infinite;
  }

  .video-container {
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }

  .video-container video {
    width: 100%;
    max-height: 360px;
    object-fit: cover;
    border-radius: 10px;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: pulse 1.5s infinite;
  }
  
  .play-button:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .play-button svg {
    width: 40px;
    height: 40px;
    fill: #183135;
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  
  @media (max-width: 1024px) {
    .sermon-container {
      flex-direction: column;
    }
    
    .sermon-content {
      margin-bottom: 40px;
    }
    
    .sermon-video {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .sermon-section {
      border-radius: 30px;
      padding: 40px 20px;
    }
    
    .sermon-content h2 {
      font-size: 28px;
    }
    
    .sermon-buttons button {
      padding: 10px 20px;
      font-size: 14px;
    }
    
    .play-button {
      width: 60px;
      height: 60px;
    }
    
    .play-button svg {
      width: 30px;
      height: 30px;
    }
  }