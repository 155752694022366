.stories-section {
    background-color: white;
    padding: 80px 40px;
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  }

  .stories-section.animate {
    opacity: 1;
    transform: translateX(0);
  }
  
  .stories-header {
    position: relative;
    margin-bottom: 40px;
  }
  
  .stories-header h2 {
    font-size: 48px;
    font-weight: bold;
    color: #000;
    position: relative;
    z-index: 2;
  }
  
  .underlay-text {
    position: absolute;
    top: 10px;
    left: 5px;
    font-size: 72px;
    color: rgba(0, 0, 0, 0.1);
    font-family: 'Brush Script MT', cursive;
    z-index: 1;
  }
  
  .stories-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  
  .story-card {
    display: flex;
    align-items: flex-start;
  }
  
  .story-image {
    width: 372px;
    height: 280px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 20px;
  }
  
  .story-content {
    display: flex;
    flex-direction: column;
  }
  
  .story-meta {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .story-category {
    background-color: #87A7F0;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 10px;
  }
  
  .story-category:hover {
    background-color: #ECD175;
    color: black;
  }
  
  .story-date {
    font-size: 14px;
    color: #666;
  }
  
  .story-title {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  
  .story-link {
    color: #000;
    text-decoration: underline;
    font-weight: bold;
  }
  
  @media (max-width: 1200px) {
    .stories-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .stories-section {
      padding: 60px 20px;
    }
  
    .stories-header h2 {
      font-size: 36px;
    }
  
    .underlay-text {
      font-size: 54px;
    }
  
    .story-card {
      flex-direction: column;
    }
  
    .story-image {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }