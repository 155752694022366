.footer {
    background-color: #0F0F0F;
    color: white;
    padding: 60px 40px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .topSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .column {
    flex: 1;
    margin-right: 20px;
  }
  
  .column:last-child {
    margin-right: 0;
  }
  
  .logo {
    max-width: 200px;
    margin-bottom: 20px;
  }
  
  .columnTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .contactInfo,
  .locationInfo,
  .stayConnectedInfo {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 10px;
  }
  
  .socialIcons {
    display: flex;
    margin-top: 15px;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .emptySpace {
    height: 40px;
  }
  
  .emailInput {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
  }
  
  .signUpButton {
    background-color: #ECD175;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .signUpButton:hover {
    background-color: #00008B;
    color: white;
  }
  
  .bottomSection {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
  }
  
  .footerNav {
    margin-bottom: 15px;
  }
  
  .footerLink {
    color: white;
    text-decoration: none;
    margin-right: 20px;
    transition: color 0.3s;
  }
  
  .footerLink:hover,
  .footerLink.active {
    color: #ECD175;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
  }
  
  @media (max-width: 768px) {
    .topSection {
      flex-direction: column;
    }
    .logo {
        max-height: 40px; 
      }
    .column {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }