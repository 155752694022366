.faqContent {
  flex: 1;
  padding-right: 40px;
}

.faqContent h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.faqContent h2 {
  font-size: 24px;
  margin-bottom: 40px;
}

.description {
  margin-bottom: 40px;
}

.aboutUsButton {
  background-color: #ECD070;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.aboutUsButton:hover {
  background-color: white;
  color: #ECD070;
}

@media (max-width: 768px) {
  .faqContent {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .faqContent h1 {
    font-size: 36px;
  }

  .faqContent h2 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .faqContent h1 {
    font-size: 28px;
  }

  .faqContent h2 {
    font-size: 18px;
  }
}