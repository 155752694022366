.banner-section {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/bannerImg.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 0 0 50px 50px;
    overflow: hidden;
    z-index: 1; 
  }
  
  .banner-content {
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
  }
  
  .left-content {
    max-width: 50%;
  }
  
  .left-content h1 {
    font-size: 64px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  
  .service-time {
    color: white;
    font-style: italic;
    font-size: 24px;
  }
  
  .right-content .watch-live-circle {
    width: 200px;
    height: 200px;
    border: 2px solid var(--hover-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--hover-color);
    font-size: 24px;
    animation: heartbeat 1.5s infinite;
    cursor: pointer;
  }
  
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @media (max-width: 768px) {
    .banner-content {
      flex-direction: column;
      text-align: center;
      padding: 0 20px;
    }
  
    .left-content {
      max-width: 100%;
      margin-bottom: 30px;
    }
  
    .left-content h1 {
      font-size: 48px;
    }
  
    .service-time {
      font-size: 20px;
    }
  
    .right-content .watch-live-circle {
      width: 150px;
      height: 150px;
      font-size: 20px;
    }
  }