.imageSection {
  /* width: 100%; */
  max-width: 100%;
  height: 500;
  /* padding-bottom: 10%;  */
  border-radius: 30px;
  background-image: url('../../../../assets/images/FAQImg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media (max-width: 768px) {
  .imageSection {
    border-radius: 10px;
  }
}