.connectSection {
    background: linear-gradient(to left, rgba(255,255,255,0.8), rgba(255,255,255,0)), #ECD073;
    padding: 80px 0 120px; /* Extra padding at the bottom for footer overlap */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    z-index: 1; /* Ensure this is below the footer */
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .title {
    font-size: 48px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .connectSection {
      padding: 60px 20px 100px;
    }
  
    .title {
      font-size: 36px;
    }
  
    .description {
      font-size: 16px;
    }
  }