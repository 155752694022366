.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height for the fallback loader */
    background-color: #f9f9f9; /* Light background for better visibility */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3; /* Light gray border */
    border-top: 6px solid #3498db; /* Blue top border for spinner animation */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  