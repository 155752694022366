.quoteCarousel {
  width: 100%;
  max-width: 853px;
  height: auto;
  min-height: 264.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
}

.quoteContent {
  text-align: center;
  color: white;
  flex-grow: 1;
  transition: opacity 0.5s ease-in-out;
}

.author {
  margin-top: 10px;
  font-style: italic;
}

.carouselButton {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carouselButton:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.carouselButton.left {
  left: 10px;
}

.carouselButton.right {
  right: 10px;
}

@media (max-width: 768px) {
  .quoteCarousel {
    padding: 10px;
  }

  .carouselButton {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .quoteContent {
    font-size: 14px;
  }

  .carouselButton {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}