.faqQuestions {
  flex: 1;
}

.faqItem {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0;
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqQuestion button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.faqQuestion button:hover {
  transform: scale(1.1);
}

.faqAnswer {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #ECD070;
}

@media (max-width: 480px) {
  .faqQuestion {
    font-size: 14px;
  }

  .faqQuestion button {
    font-size: 18px;
  }
}