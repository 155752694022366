.faqSection {
  background-color: #183135;
  border-radius: 0 0 20px 20px;
  color: white;
  padding: 40px;
  display: flex;
  margin-top: -20px;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .faqSection {
    flex-direction: column;
    padding: 20px;
    margin-top: -30px;
  }
}